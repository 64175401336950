<template>
  <b-card no-body>
    <b-card-header class="pt-1 pb-1">
      <h5 class="mb-0 flex-grow-1 text-primary">
        Bộ lọc (Hồ Chí Minh)
      </h5>
      <div class="d-flex gap-1 align-items-center">
        <b-button
          v-b-tooltip.hover.bottom="'Làm mới bộ lọc'"
          variant="flat-primary"
          size="sm"
          class="p-0"
          @click="handleClearSearch"
        >
          <feather-icon
            :icon="searching ? 'SettingsIcon' : 'XSquareIcon'"
            size="24"
            class="cursor-pointer"
            :class="searching ? 'spinner' : ''"
          />
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          @click="handleSearch"
        >
          Tìm kiếm
        </b-button>
      </div>
    </b-card-header>
    <div class="card-body">
      <b-row>
        <b-col
          cols="12"
          lg="4"
        >

          <!--            Tên công ty-->
          <dynamic-form
            :id="'filter-form-company_name'"
            :value="value.company_name"
            :type="'number'"
            placeholder="Tên công ty"
            :label="'Tên công ty'"
            :class="'form-group-hor'"
            @update-value="val => value.company_name = val"
          />
          <!--              quận huyện-->
          <dynamic-form
            :id="'filter-form-quanhuyen'"
            :value="value.district_id"
            :type="'select'"
            :label="'Quận/Huyện'"
            value-field="id"
            text-field="name"
            :class="'form-group-hor'"
            :options="districts"
            :select-default="false"
            @update-value="val => value.district_id = val"
          />
        </b-col>
        <b-col
          cols="12"
          lg="4"
        >

          <!--              Phường/Xã-->
          <dynamic-form
            :id="'filter-form-phuongxa'"
            :value="value.ward_id"
            :type="'select'"
            :label="'Phường/Xã'"
            value-field="id"
            text-field="name"
            :class="'form-group-hor'"
            :options="wards"
            :select-default="false"
            @update-value="val => value.ward_id = val"
          />

          <!--              Đường-->
          <dynamic-form
            :id="'filter-form-duong'"
            :value="value.street_id"
            :type="'select'"
            :label="'Đường'"
            value-field="id"
            text-field="name"
            :class="'form-group-hor'"
            :options="streets"
            :select-default="false"
            @update-value="val => value.street_id = val"
          />

        </b-col>

        <b-col
          cols="12"
          lg="4"
        >
          <!--          <div-->
          <!--            class="form-group-hor mb-1"-->
          <!--          >-->
          <!--            <label>Số lầu</label>-->
          <!--            <div class="grid-2-columns">-->
          <!--              <b-input-group-->
          <!--                append="≤"-->
          <!--                class="input-group-merge"-->
          <!--              >-->
          <!--                <b-form-input-->
          <!--                  v-model="value.floor_number"-->
          <!--                  placeholder="Tối thiểu"-->
          <!--                />-->
          <!--              </b-input-group>-->
          <!--              <b-input-group-->
          <!--                append="≤"-->
          <!--                class="input-group-merge"-->
          <!--              >-->
          <!--                <b-form-input-->
          <!--                  v-model="value.max_floor_number"-->
          <!--                  placeholder="Tối đa"-->
          <!--                />-->
          <!--              </b-input-group>-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="form-group-hor mb-1">
            <label>Ngang x Dài</label>
            <div class="grid-2-columns">
              <b-input-group
                append="≤"
                class="input-group-merge"
              >
                <b-form-input
                  v-model="value.width"
                  placeholder="Tối thiểu"
                />
              </b-input-group>
              <b-input-group
                append="≤"
                class="input-group-merge"
              >
                <b-form-input
                  v-model="value.length"
                  placeholder="Tối thiểu"
                />
              </b-input-group>
            </div>
          </div>

          <!--            Diện tích-->
          <dynamic-form
            :id="'filter-form-acreage'"
            :value="value.acreage"
            :type="'number'"
            placeholder="Tối thiểu"
            :label="'Diện tích'"
            :class="'form-group-hor'"
            append-text="≤"
            @update-value="val => value.acreage = val"
          />

        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCol, BFormInput, BInputGroup, BRow, VBTooltip,
} from 'bootstrap-vue'
import DynamicForm from '@core/dynamic-form/DynamicForm.vue'
import useCustomerOfficeList from '@/views/customer-office/list/useCustomerOfficeList'
import { computed, ref, watch } from '@vue/composition-api'
import ability from '@/libs/acl/ability'
import { subject } from '@casl/ability'
import store from '@/store'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    DynamicForm,
    BButton,
    BCardHeader,
    BFormInput,
    BInputGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Object,
      require: true,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ability,
      subject,
    }
  },
  created() {
    this.$call(store.dispatch('app/fetchCareers'))
  },
  setup(props, { emit }) {
    const searching = ref(null)

    const careers = computed(() => store.state.app.careers)

    const {
      wards, streets, districts, users, customerListMenuType,
    } = useCustomerOfficeList()

    const handleSearch = () => {
      emit('update:loading', true)
      if (searching.value) {
        clearTimeout(searching.value)
        searching.value = null
        emit('update:loading', false)
      }
      // new search delay
      searching.value = setTimeout(() => {
        emit('search')
        // waiting for vue update -> parent -> child(here)
        setTimeout(() => {
          clearTimeout(searching.value)
          searching.value = null
          emit('update:loading', false)
        }, 50)
      }, 500)
    }

    const handleClearSearch = () => {
      searching.value = null
      emit('clear-filter')
      emit('update:loading', false)
    }

    watch(props.value, () => {
      handleSearch()
    })

    watch(customerListMenuType, () => {
      handleClearSearch()
    })

    return {
      searching,
      districts,
      customerListMenuType,
      users,
      wards,
      streets,
      careers,
      handleSearch,
      handleClearSearch,
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/quill.scss';
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
